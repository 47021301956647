import React from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const bobAnimation = {
  animation: 'bobUpDown 5s ease infinite',
  position: 'relative',
};
function Dashboard() {
  return (
    <>
      <Typography variant="h3">Dashboard</Typography>
      <br />
      <Stack direction="row" spacing={2} alignItems="center" sx={bobAnimation}>
        <ArrowBackIcon color="text.secondary" />
        <Typography varaint="h5">Please select a group to view from the sidebar</Typography>
      </Stack>
    </>
  );
}

export default Dashboard;
