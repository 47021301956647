import React from 'react';

import WarningAmber from '@mui/icons-material/WarningAmber';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';

function CancelButton({ color, isDirty, onClick, message, children }) {
  const [open, setOpen] = React.useState(false);

  const handleClick = React.useCallback(() => {
    if (!isDirty) {
      onClick();
    } else {
      setOpen(true);
    }
  }, [isDirty, onClick]);

  const handleDiscard = React.useCallback(() => {
    setOpen(false);
    onClick();
  }, [onClick]);

  const handleClose = React.useCallback(() => setOpen(false), []);

  return (
    <>
      <Button
        color={color}
        variant="text"
        data-cy="cancel-button"
        onClick={handleClick}
        disabled={open}
      >
        {children}
      </Button>
      <Dialog hideBackdrop open={open}>
        <DialogTitle style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
          <WarningAmber color="warning" />
          &nbsp;&nbsp;Discard changes?
        </DialogTitle>
        <Divider sx={{ bgcolor: 'secondary.main' }} />
        <DialogContent>
          {message || 'Any information you entered into the form will not be saved.'}
        </DialogContent>
        <DialogActions>
          <Box m={2}>
            <Button color="secondary" onClick={handleClose}>
              Cancel
            </Button>
          </Box>
          <Box m={2}>
            <Button
              data-cy="discard-confirmation-button"
              variant="contained"
              color="secondary"
              onClick={handleDiscard}
            >
              Discard
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CancelButton;
