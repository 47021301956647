import React from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';

function PageLoading() {
  return (
    <Grid container>
      <Grid item sx={{ mt: 10 }} align="center">
        <CircularProgress size={150} />
      </Grid>
    </Grid>
  );
}

export default PageLoading;
