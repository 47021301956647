/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */

import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';

import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

function FormStringInput({
  // Props
  control,
  name,
  label,
  id,
  type,
  inputProps,
  defaultValue,
  required = false,
  disabled = false,
  options,
  rules,
  multiline = false,
  rows,
  autocomplete = false,
  selectMultiple = false,
  checkbox = false,
  disableCloseOnSelect = false,
  getOptionDisabled,
}) {
  const context = useFormContext();
  const textFieldProperties = React.useMemo(
    () => ({
      required,
      label,
      id,
      type,
      inputProps,
      select: !!options && !autocomplete,
      SelectProps: selectMultiple ? { multiple: true } : undefined,
      variant: 'standard',
      disabled,
      multiline,
    }),
    [
      autocomplete,
      disabled,
      id,
      inputProps,
      label,
      multiline,
      options,
      required,
      selectMultiple,
      type,
    ]
  );

  if (autocomplete) {
    return (
      <Controller
        shouldUnregister
        name={name}
        control={context?.control || control}
        rules={rules}
        render={({ field: { onChange }, fieldState: { error } }) => {
          const handleChange = (_, value) => onChange(value);
          return (
            <Autocomplete
              defaultValue={defaultValue || context?.defaultValues[name]}
              autoHighlight
              disableCloseOnSelect={disableCloseOnSelect}
              multiple={selectMultiple}
              disabled={disabled}
              options={options}
              loading={!options.length}
              getOptionLabel={(option) => option.name}
              getOptionKey={(option) => option.id}
              onChange={handleChange}
              getOptionDisabled={getOptionDisabled}
              value={context?.watch(name)}
              inputValue={context?.watch(name)}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  {checkbox && <Checkbox sx={{ mr: 8 }} checked={selected} />}
                  {option.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id={id}
                  name={name}
                  fullWidth
                  variant="standard"
                  label={label}
                  disabled={disabled}
                  error={!!error}
                  helperText={error?.message}
                  required={required}
                />
              )}
            />
          );
        }}
      />
    );
  }

  return (
    <Controller
      name={name}
      control={context?.control || control}
      defaultValue={defaultValue || context?.defaultValues[name]}
      shouldUnregister
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
          {...textFieldProperties}
          multiline={multiline}
          rows={multiline ? rows : ''}
          onChange={onChange}
          value={value}
          error={!!error}
          helperText={error?.message}
          fullWidth
        >
          {!!options &&
            options.map((option) => (
              <MenuItem
                key={option.id}
                value={option.value ?? option.id}
                disabled={option.disabled}
                id={`${id}-${option.id}`}
                data-cy={`${id}-${option.id}`}
              >
                {option.name}
              </MenuItem>
            ))}
        </TextField>
      )}
      rules={rules}
    />
  );
}

FormStringInput.propTypes = {
  /**
   * The [control](https://react-hook-form.com/api/useform/control)
   * object provided by invoking `useForm`.
   * Optional when using `FormProvider`.
   *
   * @example
   * ```js
   * import { useForm } from 'react-hook-form';
   * const { control, handleSubmit, getValues } = useForm();
   * ```
   * pass in control
   */
  control: PropTypes.object,

  /**
   * Unique name of your input field
   */
  name: PropTypes.string.isRequired,

  /**
   * The label content
   */
  label: PropTypes.string,

  /**
   * The id of your input field
   */
  id: PropTypes.string,

  /**
   * Type of the `input` element
   */
  type: PropTypes.string,

  /**
   * Input props for the input field, this can set the minimum number the scroll can reach,
   * the maximum number the scroll can reach, and the precision the input can have.
   * *** This is important when using type: 'number' ***
   */
  inputProps: PropTypes.object,

  /**
   * **Important:** cannot apply undefined to `defaultValue`
   * * You need to either set `defaultValue` at the field-level
   *   or `useForm`'s `defaultValue`s.  Undefined is not a valid value.
   * * If your form will invoke `reset` with default values,
   *   you will need to provide `useForm` with `defaultValues`
   */
  defaultValue: PropTypes.node,

  /**
   * If `true`, the label is displayed as required
   * and the input element is required.
   */
  required: PropTypes.bool,

  /**
   * If `true` the component is disabled
   */
  disabled: PropTypes.bool,

  /**
   * If `true` enables a select menu
   */
  options: PropTypes.array,

  /**
   * Validation rules in the same format for `register`,
   * which includes:
   * required, min, man, minLength, maxLength, pattern, validate
   *
   * @example
   * ```jsx
   * rules={{ required: true }}
   * ```
   */
  rules: PropTypes.object,

  /**
   * If `true`, a multiline textarea element is used for the input.
   */
  multiline: PropTypes.bool,

  /**
   * If `multiline` is `true`, `rows` will be used as the number of rows displayed
   * in the multiline text input.
   */
  rows: PropTypes.number,

  /**
   * If `true`, enables a select menu that allows you to search for options by typing in the
   * input.
   */
  autocomplete: PropTypes.bool,

  /**
   * If `true`, displays a checkbox on the drop down of an autocomplete component
   */
  checkbox: PropTypes.bool,

  /**
   * If `true`, the drop down menu on the autocomplete component will stay open
   * after an item is selected
   */
  disableCloseOnSelect: PropTypes.bool,

  /**
   * Determine which options should be disabled in the dropdown of the autocomplete
   */
  getOptionDisabled: PropTypes.func,
};

export default FormStringInput;
