import React from 'react';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import UhOhRobot from '../assets/404-robot.svg';

function ErrorPage() {
  return (
    <Container>
      <Grid
        container
        spacing={12}
        alignItems="center"
        sx={{
          minWidth: '100%',
          minHeight: '70vh',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Grid item xs={12} sm={6}>
          <Typography variant="h4">404 Page Not Found</Typography>
          <Typography variant="subtitle1">Sorry, an unexpected error has occurred.</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <img src={UhOhRobot} alt="404" />
        </Grid>
      </Grid>
    </Container>
  );
}

export default ErrorPage;
