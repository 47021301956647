import React from 'react';
import PropTypes from 'prop-types';

import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import Close from '@mui/icons-material/Close';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import Info from '@mui/icons-material/Info';
import WarningAmber from '@mui/icons-material/WarningAmber';

import ReactAlert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import { useTheme } from '@mui/material/styles';

function Alert({ title, message, setMessage, level, variant, 'data-cy': dataCy }) {
  const dialogTitle = React.useMemo(
    () => ({
      error: (
        <>
          <ErrorOutline color="error" />
          &nbsp;&nbsp;{title || 'Error'}
        </>
      ),
      warning: (
        <>
          <WarningAmber color="warning" />
          &nbsp;&nbsp;{title || 'Warning'}
        </>
      ),
      info: (
        <>
          <Info color="info" />
          &nbsp;&nbsp;{title || 'Info'}
        </>
      ),
      success: (
        <>
          <CheckCircleOutline color="success" />
          &nbsp;&nbsp;{title || 'Success'}
        </>
      ),
    }),
    [title]
  );

  const handleClose = React.useCallback(() => setMessage(null), [setMessage]);
  const theme = useTheme();

  if (variant === 'dialog') {
    return (
      <Dialog open={!!message} maxWidth="sm" data-cy={dataCy} fullWidth>
        <DialogTitle style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
          {dialogTitle[level]}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        {setMessage && (
          <DialogActions>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleClose}
              autoFocus
              data-cy="close-alert"
            >
              Okay
            </Button>
          </DialogActions>
        )}
      </Dialog>
    );
  }
  if (variant === 'snackbar') {
    return (
      <Snackbar
        open={!!message}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        sx={{ bottom: { xs: 90, sm: 0 }, maxWidth: 'lg' }}
        data-cy={dataCy}
      >
        <ReactAlert
          severity={level}
          variant="outlined"
          action={
            setMessage && (
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={handleClose}
                data-cy="close-alert"
              >
                <Close fontSize="inherit" />
              </IconButton>
            )
          }
          sx={{
            mb: 2,
            backgroundColor: theme.palette.background.paper,
          }}
        >
          {message}
        </ReactAlert>
      </Snackbar>
    );
  }
  return (
    <Box sx={{ width: '100%' }}>
      <Collapse in={!!message}>
        <ReactAlert
          severity={level}
          data-cy={dataCy}
          action={
            setMessage && (
              <IconButton
                aria-label="close"
                color={level === 'info' ? 'secondary' : 'inherit'}
                size="small"
                onClick={handleClose}
                data-cy="close-alert"
              >
                <Close fontSize="inherit" />
              </IconButton>
            )
          }
          sx={{ ...(level === 'info' && setMessage && { backgroundColor: 'white' }) }}
        >
          {message}
        </ReactAlert>
      </Collapse>
    </Box>
  );
}

Alert.propTypes = {
  title: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  setMessage: PropTypes.func,
  level: PropTypes.string.isRequired,
  variant: PropTypes.string,
  'data-cy': PropTypes.string,
};

export default Alert;
