import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Alert from '../../components/common/Alert.jsx';
import GroupSettings from '../../components/GroupSettings.jsx';

function Settings() {
  const [error, setError] = React.useState(null);

  return (
    <Box sx={{ p: 5 }}>
      <Alert data-cy="error-alert" message={error} setMessage={setError} level="error" />
      <Typography sx={{ fontSize: 14 }} color="text.secondary">
        Settings
      </Typography>
      <Typography variant="h4" component="div">
        Groups
      </Typography>
      <GroupSettings />
    </Box>
  );
}

export default Settings;
